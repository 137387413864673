<template>
  <div>
    <div class="container class-name post-breadcrumb-spacer">
      <div class="row justify-content-center">
        <div class="col-12 col-sm-10 col-md-8 col-lg-6 col-xl-4 mt-5">
          <h2 class="text-center pb-3">
            Name your {{ multigroupLabel.singular }}
          </h2>
          <div class="card">
            <div class="card-body">
              <label for="class-name" :class="{ 'text-danger': errors.has('Class name') }">Name your {{ multigroupLabel.singular }}</label>
              <input id="class-name" v-model="name" v-validate="'required'"
                     type="text" class="form-control" :class="{ 'is-invalid': errors.has('Class name') }"
                     name="Class name" placeholder="Enter name"> <!-- TODO: Refactor "CLASS NAME"-->
            </div>
            <div v-if="multigroupLabel.singular === 'class'" class="card-body">
              <label for="class-name">Create class code</label>
              <input id="class-code" v-model="code" type="text"
                     class="form-control" placeholder="Enter code (optional)">
              <small class="text-muted">Optional: Students will use this code to enroll in your class</small>
            </div>
            <div class="card-body">
              <button type="button" class="btn btn-primary float-right" :disabled="!isFormValid"
                      @click="nextNav">
                Next
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LessonPlanName',
  computed: {
    isFormValid() {
      return Object.keys(this.veeFields).every(key => this.veeFields[key].valid);
    },
    multigroupLabel() {
      return this.$store.getters['header/getMultigroupLabel'];
    },
    name: {
      get: function() {
        return this.$store.getters['lessonBuilder/getName'];
      },
      set: function(name) {
        this.$store.commit('lessonBuilder/setName', name);
      }
    },
    code: {
      get: function() {
        return this.$store.getters['lessonBuilder/getCode'];
      },
      set: function(code) {
        this.$store.commit('lessonBuilder/setCode', code);
      }
    }
  },
  methods: {
    /**
     * Commit name/code to store and move to next step
     */
    nextNav() {
      this.$store.commit('lessonBuilder/setName', this.name);
      // Only commit access code to store if set jhamm 7/10/18
      if (this.name) {
        this.$store.commit('lessonBuilder/setCode', this.code);
      }
      this.$router.push({ name: 'LessonPlanSubject' }).catch(err => {});
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
